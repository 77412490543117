@import "../../../assets/styles/variables";
.header {
  position: fixed;
  top: 0px;
  z-index: 1500;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(6px);
  width: 100%;
  padding: 16px 0;
  opacity: 1;
  transition: all ease-in-out 0.2s;
  &.disable {
    top: -100px;
    opacity: 0;
  }
  .container {
    margin: 0 auto;
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo {
      max-height: 54px;
      width: 253px;
      cursor: pointer;
    }
    .menu {
      .hamburgerContainer {
        padding: 10px 0;
        cursor: pointer;
        display: none;
        &.active {
          .hamburger {
            width: 20px;
            height: 2px;
            transform: rotate(45deg);
            transition: all ease-in-out 0.2s;
            &::before {
              display: none;
            }
            &::after {
              bottom: unset;
              transform: rotate(-90deg);
              transition: all ease-in-out 0.2s;
            }
          }
        }
        .hamburger {
          width: 28px;
          height: 3px;
          border-radius: 4px;
          background-color: #b91724;
          position: relative;
          &::after,
          &::before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 4px;
            background-color: #b91724;
            position: absolute;
          }
          &::before {
            top: -8px;
          }
          &::after {
            bottom: -8px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1280px) {
  .header {
    .container {
      width: 100%;
      padding: 0 24px;
      .logo {
        width: 150px;
        max-height: 32px;
      }
      .menu {
        .hamburgerContainer {
          display: block;
        }
      }
    }
  }
}
