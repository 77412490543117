.home {
  overflow-y: scroll;
  height: 100vh;
  position: relative;
  .topContainer {
    background: url("./images/top_bg.png") no-repeat;
    background-size: cover;
    height: 0;
    padding-bottom: 50%;
    position: relative;
    .leftText {
      writing-mode: vertical-lr;
      color: rgba(255, 255, 255, 0.6);
      font-size: var(--fontSetting-middle);
      font-weight: 400;
      line-height: 150%; /* 21px */
      letter-spacing: 4.2px;
      position: absolute;
      left: 52px;
      top: 50%;
      transition: all ease-in-out 0.5s;
      transform: translate(0, -50%);
      opacity: 1;
      &.disabled {
        opacity: 0;
        transform: translate(0, 100px);
      }
    }
    .content {
      position: absolute;
      left: 320px;
      top: 50%;
      transform: translate(0, -50%);
      transition: all ease-in-out 0.5s;
      opacity: 1;
      &.disabled {
        opacity: 0;
        transform: translate(-200px, -50%);
      }
      .title {
        color: #fff;
        font-size: var(--fontSetting-3x-bigger);
        font-weight: 600;
        line-height: 100%; /* 50px */
        position: relative;
        text-shadow: rgba(0, 0, 0, 0.4) 0.05em 0.1em 0.1em;
        &::after {
          content: "";
          position: absolute;
          bottom: -30px;
          left: 0;
          width: 40px;
          height: 6px;
          background: #fff;
          box-shadow: rgba(0, 0, 0, 0.4) 0.05em 0.1em 0.1em;
        }
      }
      .desc {
        color: #fff;
        font-size: var(--fontSetting-bigger);
        font-weight: 400;
        line-height: 160%; /* 28.8px */
        margin-top: 70px;
        max-width: 426px;
      }
    }
    .soccer,
    .roulette,
    .tiger,
    .bull,
    .shark,
    .girl,
    .pig,
    .god,
    .tb {
      transition: all ease-in-out 0.5s;
      opacity: 1;
      position: absolute;
    }
    .soccer {
      right: 16%;
      bottom: 0;
      max-width: 49.8%;
      &.disabled {
        right: 0;
        bottom: 40%;
        max-width: 30%;
        opacity: 0;
      }
    }
    .roulette {
      right: 0;
      bottom: 0;
      max-width: 43.5%;
      &.disabled {
        right: 20%;
        bottom: 20%;
        opacity: 0;
      }
    }
    .shark {
      right: 33%;
      top: 14%;
      max-width: 16.4%;
      &.disabled {
        right: 12%;
        top: 40%;
        max-width: 10%;
        opacity: 0;
      }
    }
    .girl {
      right: 2%;
      top: 4%;
      max-width: 24.6%;
      &.disabled {
        right: 12%;
        top: 30%;
        max-width: 10%;
        opacity: 0;
      }
    }
    .bull {
      right: 12%;
      top: 12%;
      max-width: 27.5%;
      &.disabled {
        opacity: 0;
      }
    }
    .god {
      right: 13%;
      top: 40%;
      max-width: 19.5%;
      &.disabled {
        right: 13%;
        top: 35%;
        max-width: 10%;
        opacity: 0;
      }
    }
    .tiger {
      right: 28%;
      top: 54%;
      max-width: 16.5%;
      &.disabled {
        right: 12%;
        top: 30%;
        max-width: 8%;
        opacity: 0;
      }
    }
    .pig {
      right: 5%;
      top: 54%;
      max-width: 14.8%;
      &.disabled {
        right: 18%;
        top: 30%;
        max-width: 6%;
        opacity: 0;
      }
    }
    .tb {
      right: 28%;
      top: 28%;
      max-width: 12.8%;
      &.disabled {
        opacity: 0;
      }
    }
  }
  .normalContainer {
    padding: 80px 0;
    width: 70%;
    margin: 0 auto;
    text-align: center;
    &.partnerContainer {
      background: url("./images/partner_background.png") no-repeat;
      background-size: cover;
      height: 620px;
      width: 100%;
      .container {
        display: flex;
        width: 70%;
        margin: 0 auto;
        text-align: start;
        .title {
          color: #fff;
          margin-bottom: 16px;
        }
        .subTitle {
          color: #fff;
          margin-bottom: 8px;
        }
        .desc {
          color: rgba(255, 255, 255, 0.8);
        }
        .content {
          margin-left: 40px;
          grid-template-columns: repeat(7, 1fr);
          grid-gap: 0;
          .img {
            margin: 0 auto;
          }
        }
      }
    }
    &.versionContainer {
      background: #f4f4f4;
      width: 100%;
      .container {
        width: 70%;
        margin: 0 auto;
      }
      .title {
        margin-bottom: 16px;
      }
      .subTitle {
        margin-bottom: 4px;
      }
      .content {
        display: block;
        width: 100%;
        position: relative;
        .swiperNavigate {
          position: absolute;
          width: 60px;
          height: 60px;
          background: linear-gradient(180deg, #ffb8b8 0%, #b91724 100%);
          top: 50%;
          transform: translate(0, -50%);
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 99;
          cursor: pointer;
          .icon {
            font-size: 30px;
            color: #fff;
          }
          &.swiperPrev {
            left: -100px;
          }
          &.swiperNext {
            right: -100px;
          }
          &.disable {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }
      }
    }
    .title {
      color: #000;
      font-size: var(--fontSetting-2x-normal);
      font-weight: 600;
      line-height: 100%; /* 30px */
      margin-bottom: 24px;
    }
    .subTitle {
      color: #000;
      font-size: var(--fontSetting-large);
      font-weight: 400;
      line-height: 160%; /* 25.6px */
      margin-bottom: 40px;
    }
    .desc {
      color: rgba(0, 0, 0, 0.8);
      font-size: var(--fontSetting-bigger);
      font-weight: 400;
      line-height: 150%; /* 21px */
    }
    .content {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 16px;
      .card {
        padding: 40px 40px;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
        position: relative;
        border-radius: 10px 30px;
        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 0%;
          z-index: -2;
          border-radius: 10px 30px;
        }
        &:hover {
          z-index: 1;
          &::before {
            z-index: -1;
            background: #b91724;
            transition: all ease-in-out 0.5s;
            height: 100%;
          }
          .cardTitle {
            transform: translate(0, 40px);
            opacity: 0;
          }
          .cardContent {
            opacity: 1;
            z-index: 1;
            transform: translate(-50%, -160px);
          }
        }
        .cardTitle {
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all ease-in-out 0.4s;
          .text {
            margin-left: 20px;
            color: #000;
            font-size: var(--fontSetting-large);
            font-weight: 400;
            line-height: 100%; /* 20px */
          }
        }
        .cardContent {
          position: absolute;
          width: 80%;
          bottom: -160px;
          opacity: 0;
          left: 50%;
          color: #fff;
          z-index: -2;
          font-weight: 500;
          line-height: 160%; /* 22.4px */
          font-size: var(--fontSetting-normal);
          transition: all ease-in-out 0.5s;
          transform: translate(-50%, 0);
          height: 148px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .contentTitle {
            color: rgba(255, 255, 255, 1);
            font-size: var(--fontSetting-larger);
            margin-bottom: 6px;
            font-weight: 700;
          }
        }
      }
    }
  }
  .lastContainer {
    background: url("./images/last_bg.jpg") no-repeat;
    background-size: cover;
    height: 0;
    padding-bottom: 41%;
    position: relative;
    .content {
      position: absolute;
      left: 320px;
      top: 50%;
      transform: translate(0, -50%);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .title {
        color: #1e1e1e;
        font-size: var(--fontSetting-3x-bigger);
        font-weight: 400;
        line-height: 100%; /* 50px */
        opacity: 1;
        transition: all ease-in-out 0.5s;
        &.disabled {
          opacity: 0;
          transform: translate(0, 200px);
        }
        & > span {
          color: #b91724;
          font-weight: 600;
        }
      }
      .qrCodeContainer {
        background: rgba(13, 45, 106, 0.06);
        padding: 20px;
        margin: 40px 0;
        text-align: center;
        .qrCode {
          width: 190px;
        }
        .label {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 10px;
          .name {
            color: #303030;
            font-size: var(--fontSetting-huge);
            font-weight: 600;
            line-height: 140%; /* 33.6px */
            margin-left: 8px;
          }
        }
      }
      .desc {
        color: #4e4e4e;
        font-size: var(--fontSetting-large);
        font-weight: 400;
        line-height: 150%; /* 30px */
        max-width: 592px;
        opacity: 1;
        transition: all ease-in-out 0.5s;
        &.disabled {
          opacity: 0;
          transform: translate(0, -200px);
        }
      }
    }
    .money,
    .people {
      transition: all ease-in-out 0.5s;
      opacity: 1;
      position: absolute;
    }
    .money {
      right: 5%;
      bottom: 10%;
      max-width: 38.5%;
      &.disabled {
        right: 25%;
        bottom: 40%;
        max-width: 30%;
        opacity: 0;
      }
    }
    .people {
      right: 6%;
      bottom: 0;
      max-width: 46.9%;
      &.disabled {
        right: 20%;
        bottom: -10%;
        max-width: 38%;
        opacity: 0;
      }
    }
  }
  .footer {
    background: #202020;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 0 105px;
    position: relative;
    &::after,
    &::before {
      position: absolute;
      content: "";
      width: 40%;
      height: 52px;
      background: #000;
      bottom: 0;
    }
    &::before {
      border-radius: 0px 100px 0px 0px;
      left: 0;
    }
    &::after {
      border-radius: 100px 0px 0px 0px;
      right: 0;
    }
    .center {
      text-align: center;
      .info {
        margin: 27px 0 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        .items {
          display: flex;
          align-items: center;
          &:not(:last-child) {
            margin-right: 12px;
          }
          .data {
            margin-left: 8px;
            color: #fff;
            font-size: var(--fontSetting-normal);
            font-weight: 600;
            line-height: 140%; /* 22.4px */
          }
        }
      }
      .footerText {
        color: #fff;
        font-size: var(--fontSetting-normal);
        font-weight: 400;
        line-height: normal;
      }
    }
    .bottom {
      position: absolute;
      bottom: 16px;
      width: 142px;
      .link {
        display: flex;
        align-items: center;
        justify-content: space-around;
        cursor: pointer;
        transition: all ease-in-out 0.5s;
        .facebook,
        .twitter,
        .linkedin,
        .instagram {
          width: 24px;
          height: 24px;
          padding: 4px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          &:hover {
            transform: scale(1.3);
            background-color: #b91724;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1750px) {
  .home {
    .topContainer {
      .content {
        left: 140px;
      }
    }
    .lastContainer {
      .content {
        left: 140px;
      }
    }
  }
}
@media screen and (max-width: 1500px) {
  .home {
    .topContainer {
      .leftText {
        display: none;
      }
      .content {
        left: 24px;
      }
    }
    .normalContainer {
      width: 90%;
      &.partnerContainer {
        .container {
          width: 90%;
        }
      }
    }
    .lastContainer {
      .content {
        left: 60px;
        .title {
          font-size: var(--fontSetting-extra-large);
        }
        .qrCodeContainer {
          margin: 16px 0;
        }
        .desc {
          max-width: 440px;
          font-size: var(--fontSetting-normal);
        }
      }
    }
  }
}
@media screen and (max-width: 1280px) {
  .home {
    .topContainer {
      .content {
        .title {
          font-size: var(--fontSetting-extra-large);
        }
        .desc {
          font-size: var(--fontSetting-normal);
          max-width: 360px;
        }
      }
    }
    .normalContainer {
      width: 100%;
      padding: 40px 24px;
      &.partnerContainer {
        height: 720px;
        .container {
          display: block;
          width: 100%;
          text-align: center;
          .titleContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 40px;
            .desc {
              max-width: 343px;
            }
          }
          .content {
            margin-left: 0px;
            grid-template-columns: repeat(7, 1fr);
          }
        }
      }
      .content {
        grid-template-columns: repeat(2, 1fr);
      }
    }
    .lastContainer {
      .content {
        left: 24px;
        .qrCodeContainer {
          display: flex;
          align-items: center;
          padding: 20px;
          .qrCode {
            width: 120px;
          }
          .label {
            margin-left: 10px;
            .name {
              font-size: var(--fontSetting-middle);
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1050px) {
  .home {
    .topContainer {
      .content {
        .desc {
          margin-top: 50px;
        }
      }
    }
    .normalContainer {
      &.partnerContainer {
        height: 840px;
        .container {
          .content {
            grid-template-columns: repeat(6, 1fr);
          }
        }
      }
    }
    .lastContainer {
      .content {
        .desc {
          max-width: 300px;
        }
      }
    }
    .footer {
      &::after,
      &::before {
        width: 30%;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .home {
    .topContainer {
      background: url("./images/top_bg_mobile.png") no-repeat;
      background-size: 100%;
      height: 663px;
      max-height: 663px;
      padding-bottom: 0;
      .content {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        &.disabled {
          opacity: 0;
          transform: translate(-400px, -50%);
        }
        .title {
          font-size: var(--fontSetting-3x-bigger);
          &::after {
            left: 50%;
            transform: translate(-50%, 0);
          }
        }
        .desc {
          font-size: var(--fontSetting-bigger);
          max-width: 90%;
        }
      }
      .soccer,
      .roulette,
      .tiger,
      .bull,
      .shark,
      .girl,
      .pig,
      .god,
      .tb {
        display: none;
      }
    }
    .normalContainer {
      padding: 40px 16px;
      &.partnerContainer {
        background: url("./images/partner_background_mobile.png") no-repeat;
        background-size: 100% 100%;
        height: auto;
        .container {
          .content {
            grid-template-columns: repeat(3, 1fr);
          }
        }
      }
      &.versionContainer {
        .container {
          width: 100%;
        }
        .content {
          .swiperNavigate {
            width: 40px;
            height: 40px;
            .icon {
              font-size: 20px;
            }
            &.swiperPrev {
              left: 0px;
            }
            &.swiperNext {
              right: 0px;
            }
          }
        }
      }
      .content {
        grid-template-columns: repeat(1, 1fr);
      }
    }
    .lastContainer {
      background: url("./images/last_bg_mobile.png") no-repeat;
      background-size: 100%;
      height: 626px;
      max-height: 626px;
      padding-bottom: 0;
      .content {
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        .title {
          font-size: var(--fontSetting-3x-bigger);
        }
        .qrCodeContainer {
          display: block;
          margin: 24px 0;
          .qrCode {
            width: 190px;
          }
          .label {
            margin-left: 0;
            .name {
              font-size: var(--fontSetting-huge);
            }
          }
        }
        .desc {
          font-size: var(--fontSetting-large);
          max-width: 90%;
        }
      }
      .money,
      .people {
        display: none;
      }
    }
    .footer {
      padding: 40px 0 100px;
      &::after,
      &::before {
        display: none;
      }
      .center {
        .info {
          flex-direction: column;
          margin: 24px 0;
          .items {
            &:not(:last-child) {
              margin-bottom: 12px;
            }
          }
        }
      }
      .bottom {
        position: unset;
        margin-top: 24px;
      }
    }
  }
}
