@font-face {
  font-family: "Inter";
  src: url("../../assets/fonts/Inter/Inter-Light.ttf") format("truetype");
  font-display: auto;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../../assets/fonts/Inter/Inter-Regular.ttf") format("truetype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../../assets/fonts/Inter/Inter-Medium.ttf") format("truetype");
  font-display: auto;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../../assets/fonts/Inter/Inter-Bold.ttf") format("truetype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../../assets/fonts/Inter/Inter-Black.ttf") format("truetype");
  font-display: auto;
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
}

body {
  font-family:
    Inter,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif !important;
}
