.floatButton {
  position: fixed;
  z-index: 998;
  background-color: transparent;
  right: 20px;
  top: 30%;
  border-radius: 100px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  transition: all ease-in-out 0.2s;
  opacity: 1;
  &.disable {
    right: -100px;
    opacity: 0;
  }
  .top {
    text-align: center;
    padding: 16px 0 30px 0;
    border-radius: 100px 100px 0 0;
    background-color: #fff;
    cursor: pointer;
    .img {
      width: 48px;
    }
    .name {
      color: #018aec;
      font-size: var(--fontSetting-normal);
      font-weight: 600;
      line-height: 120%; /* 19.2px */
      margin-top: 4px;
    }
  }
  .goTop {
    height: 49px;
    width: 88px;
    background: linear-gradient(90deg, #1366e2 0%, #00b9f5 100%);
    border-radius: 0 0 100px 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    &::before {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 6px 12px 6px;
      border-color: transparent transparent #5290edf7 transparent;
      position: absolute;
      top: -12px;
      left: 50%;
      transform: translate(-50%, 0);
    }
    .text {
      color: #fff;
      font-size: var(--fontSetting-normal);
      font-weight: 600;
      line-height: 120%; /* 19.2px */
    }
  }
}

@media screen and (max-width: 768px) {
  .floatButton {
    right: 0px;
    top: 70%;
    transform: scale(0.6);
  }
}
