.mainLayout {
  display: flex;
  width: 100%;
  height: 100%;
  .main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    ::-webkit-scrollbar {
      display: none;
    }
    .container {
      height: 100%;
      background-color: var(--grey50);
      overflow: scroll;
      box-sizing: border-box;
      .container::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
