.home {
  .swiper {
    width: 100%;
    padding: 40px 0;
    .swiper-wrapper {
      .swiper-slide {
        background-position: center;
        background-size: cover;
        width: 255px;
        height: auto;
        & > img {
          display: block;
          width: 100%;
        }
        &.swiper-slide {
          opacity: 0.3;
        }
        &.swiper-slide-active {
          opacity: 1;
        }
      }
    }
    .swiper-pagination {
      // bottom: -10px;
      display: none;
      .swiper-pagination-bullet {
        width: 80px;
        height: 8px;
        background: rgba(185, 23, 36, 0.2);
        border-radius: 0;
        &.swiper-pagination-bullet-active {
          background: #b91724;
        }
      }
    }
  }
}

// @media screen and (max-width: 1280px) {
//   .home {
//     .swiper {
//       .swiper-pagination {
//         display: none;
//       }
//     }
//   }
// }
