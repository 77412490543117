.menu {
  .ant-affix {
    transform: translate(0, 22px);
  }
  .ant-anchor-wrapper {
    padding: 0;
    &::before {
      display: none;
    }
    .ant-anchor {
      .ant-anchor-ink {
        display: none;
      }
      .ant-anchor-link {
        font-size: var(--fontSetting-bigger);
        font-weight: 500;
        line-height: 120%; /* 21.6px */
        position: relative;
        padding-left: 32px;
        &::after {
          content: "";
          position: absolute;
          right: -16px;
          top: 50%;
          transform: translate(0, -50%);
          width: 1px;
          height: 14px;
          background-color: rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .menu {
    .ant-anchor {
      display: none;
    }
  }
}
